@import '../variables';

#work {
  padding-left: .5rem;
  padding-right: .5rem;
  max-width: 100%;
}

.work-item {
  font-size: 125%;
  margin-bottom: 5rem;

  .project-title {
    position: relative;
    margin-bottom: 2rem;

    h3 {
      margin-bottom: 0;
      margin-top: 0;
      font-size: 3rem;
    }

    h4 {
      margin-bottom: 0;
      margin-top: .5rem;
      font-weight: 300;
      font-style: italic;
      font-size: 1.5rem;
    }
  }

  .featured-project-image {
    margin-top: 2rem;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.work-grid  {
  font-size: 125%;
  max-width: 100%;

  p {
    font-weight: bold;
  }

  a {
    text-decoration: none;
  }

  img {
    width: 100%;
    height: auto;
  }
}
