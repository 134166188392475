@import '../variables';

.heading-container {
  margin-bottom: 3rem;

  h2 {
    font-family: $sans;
    font-weight: 300;
    font-size: 4rem;
    margin: 0;

    &.subheading {
      font-size: 1.5rem;
      font-family: $serif;
    }
  }
}

@media screen and (max-width: 1170px) {
  .heading-container {
    h2 {
      font-size: 3rem;
    }

    .divider {
      height: 1.5rem;
    }
  }
}

@media screen and (max-width: 500px) {
  .heading-container {
    margin-bottom: 1rem;

    h2 {
      font-size: 2rem;

      &.subheading {
        font-size: 1.25rem;
        position: relative;
        top: -1rem;
      }
    }

    .divider {
      height: 1rem;
      margin-top: 1rem;
    }
  }
}