// noinspection CssInvalidAtRule
@import-normalize;
@import 'flexboxgrid';

// Fonts.
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;700&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

// Variables.
@import 'variables.scss';

body {
  margin: 0;
  font-family: $sans;
  background: $white;
  color: $black;
}

strong {
  font-weight: bold;
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.background-blue {
  background-color: $blue;
}

.background-gray {
  background-color: $cream;
}

.divider {
  margin: 2rem 0;
  width: 16rem;
  height: 2rem;
  background: $blue;

  &.centered {
    margin: 2rem auto;
  }
}

@media screen and (max-width: 800px) {
  .divider {
    height: 1.5rem;
  }
}

@media screen and (max-width: 500px) {
  .divider {
    height: 1rem;
  }
}
